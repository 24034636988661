exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-oferty-jsx": () => import("./../../../src/pages/oferty.jsx" /* webpackChunkName: "component---src-pages-oferty-jsx" */),
  "component---src-pages-realizacje-jsx": () => import("./../../../src/pages/realizacje.jsx" /* webpackChunkName: "component---src-pages-realizacje-jsx" */),
  "component---src-pages-uslugi-jsx": () => import("./../../../src/pages/uslugi.jsx" /* webpackChunkName: "component---src-pages-uslugi-jsx" */),
  "component---src-pages-zespol-jsx": () => import("./../../../src/pages/zespol.jsx" /* webpackChunkName: "component---src-pages-zespol-jsx" */),
  "component---src-templates-oferta-template-jsx": () => import("./../../../src/templates/OfertaTemplate.jsx" /* webpackChunkName: "component---src-templates-oferta-template-jsx" */)
}

